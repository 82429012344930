export default {
  navTab: {
    title: 'PRIMUS',
    tab1: 'Start',
    tab2: 'WHO WE ARE',
    tab3: 'WORK WITH US',
    tab4: 'CONTACT US'
  },
  homeTop: {
    h3Title: 'OMNICHANNEL CONTACT CENTER',
    h3Title1: 'Communication and Technology Services',
    desc: 'PRIMUS TECHNOLOGY SOLUTIONS LIMITED, based in Lagos, Nigeria, has a professional team specializing in communication and technology services to provide customers with high-end and cutting-edge technical services.'
  },
  homeBottom: {
    title: 'What we offer',
    menu1: 'EFFICIENCY',
    menuDesc1: 'We operate with a management model that implements a continuous improvement process in your operation and our staff, supported by Work Force Management, Training, Business Intelligence and the Command Center.',
    menu2: 'PLATAFORMA MULTICANAL',
    menuDesc2: 'Gestionamos las interacciones con tus clientes en una plataforma multicanal que permite contactar de forma adecuada a tus clientes, por los medios que ellos buscan y se ajustan a sus necesidades.We manage interactions with your customers on a multi-channel platform that allows you to contact your customers appropriately, by the means they seek and adjust to their needs.',
    menu3: 'EXPERT STAFF',
    menuDesc3: 'We have the attraction, recruitment and training of qualified personnel, as well as an organizational culture program focused on the well-being of our collaborators to promote their professional development.',
    menu4: 'THE BEST SOLUTION OF',
    menu4br: 'CONTACT',
    menuDesc4: 'We analyze and optimize your processes to be more efficient and help make your company profitable'
  },
  bottom: {
    title: 'PRIMUS TECHNOLOGY SOLUTIONS LIMITED',
    email: 'Finance.PRIMUS@outlook.com',
    facebook: 'Facebook: Dino Outsource',
    about: 'ABOUT US',
    joinUs: 'WORK WITH US',
    contact: 'CONTACT US',
    privacyTitle: 'quick links',
    privacy: 'Notice of Privacy'
  },
  aboutUs: {
    title: 'Company Profile',
    p1: 'PRIMUS TECHNOLOGY SOLUTIONS LIMITED is a dynamic technology company based in Lagos, Nigeria, that is passionate about providing its customers with the best possible communication and technology services.',
    p2: 'With a team of experienced professionals who are experts in their fields, Primus is committed to delivering innovative solutions that meet the unique needs of its clients. ',
    p4: 'For employees',
    p5: `Whether it's developing custom software applications, creating cutting-edge communication systems, or providing top-notch IT support, Primus is dedicated to delivering exceptional service and value to its customers. With a focus on quality, reliability, and customer satisfaction, Primus is the go-to choice for businesses and individuals who demand the best in technology services.`
  },
  joinUs: {
    title: 'Recruitment',
    subTitle: 'Programme Specialist',
    descTitle: 'Job Description',
    descSubTitle: '【Programme Specialist】',
    content1: 'Due to expansion, an IMPORTANT COMPANY IN THE FINTECH BRANCH is en-',
    content2: 'we invite you to join our great team of telephone advisors!!',
    section1Title: 'We offer:',
    section1Desc1: 'Base salary 50k ~ 55K NGN',
    section1Desc2: 'Incentives for productivity from 20k ~ 100K NGN',
    section1Desc3: 'Punctuality Bonus 5000 NGN',
    section1Desc4: 'Referral Bonus 350 NGN',
    section1Desc5: 'Benefits of law from the first day',
    section1Desc6: 'Paid Training',
    section1Desc7: 'Free Coffee Break Service',
    section1Desc8: 'Dining room with soda machine (Terrace with a view of Chapultepec Castle)',
    section1Desc9: 'Game Rooms',
    section1Desc10: 'Modern Installations',
    section2Title: 'Requirements:',
    section2Desc1: '18 to 45 years old',
    section2Desc2: 'Secondary completed',
    section2Desc3: 'Documentation in order',
    section2Desc4: "No experience needed, we'll train you!!",
    section2Desc5: 'Shift from 9 to 6 from Monday to Sunday with rest during the week',
    vitae: 'How to submit (apply) your Curriculum Vitae:',
    vitaeGray: 'Application via Email',
    vitaeOrange: '“Finance.PRIMUS@outlook.com”'
  },
  contactUs: {
    title: 'CONTACT US',
    p1: 'Company Address：',
    p2: 'Cto. Interior Melchor Ocampo 469, Anzures, Miguel Hidalgo, 11590 Ciudad',
    p3: 'from Mexico, CDMX',
    p4: 'Email:',
    p5: 'Finance.PRIMUS@outlook.com',
    p6: 'Facebook：',
    p7: 'Dino Outsource'
  }
}
